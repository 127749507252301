import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import InputField from './InputField';
import TextArea from './TextArea';
import emailjs from '@emailjs/browser';
import useViewportWidth from '../Hook/useViewportWidth';
import useViewportHeight from '../Hook/useViewportHeight';

const FourthContainer = styled.div`
    width: 100%;
    height: max-content;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    align-items: center;
    justify-content: center;
    padding-block: 35px 65px;
    padding-inline: 35px;
`;

const H1WithLineAbove = styled.div`
    background-color: ${props => props.bcolor};
    font-family: ${props => props.font};
    width: ${props => props.width};
    height: max-content;
    text-align: ${props => props.align};
    font-size: 39px;
    font-weight: 800;
    color: ${props => props.color};
    padding: 80px 0 65px;
    --sal-duration: 1s;
    position: relative;
    &::before{
        content: '';
        display: block;
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 99%;
        height: 10px;
        background-color: ${props => props.lColor};;
    }
`;

const Grid = styled.div`
    display: grid;
    width: 85%;
    justify-items: center;
    row-gap: 50px;
    column-gap: 30px;
`;

const Button = styled.button`
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    width: max-content;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 15px 58px;
    background: #000;
    border: 0;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 3px;
    transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    display: block;
`;

const ButtonContainer = styled.div`
    position: relative;
    grid-column: 1 / 3;
    display: flex;
    justify-content: center;
    width: 160px;
`;

const Contact = () => {
  const width = useViewportWidth();
  const height = useViewportHeight();
  const name = useRef("");
  const email = useRef("");
  const phone = useRef("");
  const message = useRef("");
  const buttonRef = useRef(undefined);
  const sendEmail = () => {
    buttonRef.current.classList.add('clicked');
    buttonRef.current.classList.remove('unclicked');
    const content = {
        name: name.current.value,
        email: email.current.value,
        phone: phone.current.value,
        message: message.current.value
    }

    emailjs.send('service_zl55f3p', 'template_yazr23f', content, 'uczOg0jlXs3Y_lx8y')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    buttonRef.current.textContent = buttonRef.current.textContent === "Sent!" ? "Send" : "Sent!";
    buttonRef.current.disabled = true;
    setTimeout(() => {
        buttonRef.current.classList.remove('clicked');
        buttonRef.current.classList.add('unclicked');
        buttonRef.current.disabled = false;
        buttonRef.current.textContent = buttonRef.current.textContent === "Sent!" ? "Send" : "Sent!";
    }, 2000);
  };
  return (
    <FourthContainer id="contact">
        <H1WithLineAbove
            width="max-content" 
            align="center" 
            font="'Playfair Display', serif" 
            bcolor="unset" 
            color="black" 
            lColor="black"  
            data-sal="slide-down"
            data-sal-delay="300"
            data-sal-easing="ease-out-quad"
        >
            Contact
        </H1WithLineAbove>
        <Grid>
            <InputField id='name' type='text' label='Name' size='100%' startAtLine='1' endAtLine='3' ref={name}/>
            <InputField id='email' type='email' label='Email' size='100%' startAtLine='1' endAtLine='2' ref={email}/>
            <InputField id='phone' type='number' label='Phone' size='100%' startAtLine='2' endAtLine='3' ref={phone}/>
            <TextArea id='message' label='Message' size='100%' startAtLine='1' endAtLine='3' rows={(width > 900 && height > 750) ? '5' : ((width > 460 && height > 700) ? '4' : ((width > 460 && height > 650) ? '3' : (height > 650 ? '2' : '1')))} ref={message}/>
            <ButtonContainer>
                <Button className='unclicked' ref={buttonRef} onClick={sendEmail}>Send</Button>
            </ButtonContainer>
        </Grid>
    </FourthContainer>
  )
}

export default Contact