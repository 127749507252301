import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from "styled-components";
import sal from 'sal.js';
import 'sal.js/dist/sal.css';
import Intro from './Intro';
import Resume from './Resume';
import Projects from './Projects';
import Contact from './Contact';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Century";
        src: url("../../fonts/CENSCBK.TTF") format("truetype");
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Main = () => {

    useEffect(() => {
        sal();
    }, []);

    return (
        <MainContainer>
            <Intro/>
            <Resume/>
            <Projects/>
            <Contact/>
        </MainContainer>
    )
}

export default Main