import { useState, useCallback } from "react";
const browser = typeof window !== 'undefined'

const useViewportHeight = () => {
    const [height, setHeight] = useState(browser ? window.innerHeight : 0)

    const setSize = useCallback(() => {
        setHeight(window.innerHeight || 0)
    }, [])

    window.addEventListener('resize', setSize, { passive: true })
    return height
}

export default useViewportHeight
