import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import useViewportWidth from '../Hook/useViewportWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGithub, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'

const FooterContainer = styled.footer`
    width: 100%;
    box-sizing: border-box;
    height: max-content;
    min-height: 80px;
    background-color: #000;
    display: flex;
    padding: ${props => (props.width < 650 ? "2vh" : "10px")} 4.5vw;
    justify-content: space-between;
    flex-direction: ${props => (props.width < 650 ? "column" : "row")};
    gap: ${props => (props.width < 650 ? "2vh" : "unset")};
    align-items: center;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`

const FooterIconContainer = styled.a`
    width: 3vw;
    height: 3vw;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    &:hover {
        background-color: transparent;
    }

    & > .footerIcon {
        color: #000;
        font-size: 1rem;
        transition: all 0.2s;
    }

    &:hover > .footerIcon {
        scale: 1.2;
        color: #f1f1f1;
    }

    &:before {
        background: var(--color);
        content: "";
        position: absolute;
        width: 135%;
        height: 135%;
        left: -110%;
        top: 80%;
        transform: rotate(45deg);
    }

    &:hover:before {
        animation: slide 0.7s forwards;
    }

    &:has(.fa-instagram) {
        --color: linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7);
    }

    &:has(.fa-linkedin-in) {
        --color: #0077b5;
    }

    &:has(.fa-facebook) {
        --color: #0566ff;
    }

    &:has(.fa-github) {
        --color: #333;
    }
`

const FooterCopyrightText = styled.div`
    color: white;
    font-size: 0.8rem;
    font-family: Poppins, sans-serif;
    text-align: center;
`

const FooterStyle = createGlobalStyle`
    @keyframes slide {
        50% {
            left: 10%;
            top: -40%;
        }

        100% {
            left: -15%;
            top: -15%;
        }
    }
`;

const Footer = () => {
    const width = useViewportWidth();
    return (
        <FooterContainer width={width}>
            <FooterStyle />
            <FooterCopyrightText>©2024 Nguyen Nguyen Khuong, All Rights Reserved.</FooterCopyrightText>
            <FooterWrapper>
                <FooterIconContainer href="https://www.instagram.com/whitemirror1212/">
                    <FontAwesomeIcon icon={faInstagram} className='footerIcon'/>
                </FooterIconContainer>
                <FooterIconContainer href="https://www.linkedin.com/in/kh%C6%B0%C6%A1ng-nguy%E1%BB%85n-nguy%C3%AAn-149b80224/">
                    <FontAwesomeIcon icon={faLinkedinIn} className='footerIcon'/>
                </FooterIconContainer>
                <FooterIconContainer href="https://github.com/nguyennguyenkhuong1212">
                    <FontAwesomeIcon icon={faGithub} className='footerIcon'/>
                </FooterIconContainer>
                <FooterIconContainer href="https://www.facebook.com/whitemirror1212">
                    <FontAwesomeIcon icon={faFacebook} className='footerIcon'/>
                </FooterIconContainer>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer