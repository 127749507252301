import React from 'react';
import styled from 'styled-components';
import 'sal.js/dist/sal.css';

const PromptContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 90%;
  max-width: 300px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1000;
  --sal-duration: 1s;
  
  @media (max-width: 768px) {
    bottom: 20px;
    left: 0;
    right: 0;
    margin-inline: auto;
  }
`;

const PromptMessage = styled.p`
  margin: 0 0 10px;
  letter-spacing: 1.25px;
`;

const PromptButton = styled.button`
  background-color: ${props => (props.sub ? "rgb(147 147 147 / 70%);" : "#000")};
  color: #fff;
  border: none;
  padding: 10px 20px;
  width: calc(45% - 20px);
  margin: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Martel Sans", sans-serif;
  transition: all .2s ease-in-out;

  &:hover {
    scale: 1.1;
  }
`;

const MusicPrompt = ({ onAllowed, showPrompt, setShowPrompt }) => {
  
    const handleMusicChoice = () => {
    onAllowed();
    setShowPrompt(false);
  };

  if (!showPrompt) return undefined;

  return (
    <PromptContainer
        data-sal="slide-left"
        data-sal-delay="300"
        data-sal-easing="ease-out-quad"
    >
      <PromptMessage>Turn on the background music?</PromptMessage>
      <PromptButton onClick={handleMusicChoice}>Yes</PromptButton>
      <PromptButton onClick={() => {setShowPrompt(false)}} sub>No</PromptButton>
    </PromptContainer>
  );
};

export default MusicPrompt;
