import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";

const Input = styled.input`
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 1px solid grey;
    outline: 0;
    font-size: 1.3rem;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown ~ label {
        font-size: 1.4rem;
        cursor: text;
        top: 20px;
    }

    &:focus {
        ~ label {
            font-size: 1rem;
            font-weight: 800;    
          }
        padding-bottom: 6px;  
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        font-stretch: normal;
        box-shadow: 0 2px 0 0 grey;
    }

    &:required, &:invalid { 
        box-shadow: none; 
    }
`;

const Label = styled.label`
    position: absolute;
    top: 0;
    display: block;
    transition: all 0.2s ease-in-out;
    font-size: 1rem;
    color: black;
`;

const Outer = styled.div`
    position: relative;
    padding: 15px 0 0;
    width: ${props => props.size};
    font-family: 'Poppins', sans-serif;
    display: inline-grid;
    grid-column-start: ${props => props.startAtLine};
    grid-column-end: ${props => props.endAtLine}
`;

const InputField = React.forwardRef(({ id, label, size, startAtLine, endAtLine, ...rest }, ref) => {
    return (
        <Outer size={size} startAtLine={startAtLine} endAtLine={endAtLine}>
          <Input id={id} ref={ref} {...rest} />
          <Label htmlFor={id}>{label}</Label>
        </Outer>
      )
});

export default InputField