import React from 'react';
import ComSysProj from '../../img/IOT Trashbin.jpg'
import BITSProj from '../../img/BITS.png'
import FWPProj from '../../img/FWP.png'
import CapProj from '../../img/Capstone.PNG'
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faGlobe, faPlay } from '@fortawesome/free-solid-svg-icons'

const ThirdContainer = styled.div`
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: black;
    align-items: center;
    padding-bottom: 65px;
`;

const H1WithLineAbove = styled.div`
    background-color: ${props => props.bcolor};
    font-family: ${props => props.font};
    width: ${props => props.width};
    height: max-content;
    text-align: ${props => props.align};
    font-size: 39px;
    font-weight: 800;
    color: ${props => props.color};
    padding: 80px 0 65px;
    --sal-duration: 1s;
    position: relative;
    &::before{
        content: '';
        display: block;
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 99%;
        height: 10px;
        background-color: ${props => props.lColor};
    }
`;

const Title = styled.div`
    font-family: 'Martel Sans', sans-serif;
    font-weight: 900;
    font-size: calc(1.2vw + 6px);
    line-height: 30px;
    text-align: center;
    @media (max-width: 900px) {
        font-size: 1rem;
        text-align: center;
    }
`;

const Role = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: .65rem;
    text-transform: uppercase;
    letter-spacing: 3.5px;
    text-align: center;
`

const Note = styled.div`
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    text-align: justify;
    font-size: calc(0.5vw + 8px);
    width: 100%;
    @media (max-width: 900px) {
        display: none;
    }
`;

const ProjectSection = styled.div`
    display: grid;
    width: 85%;
    grid-template-rows: repeat(1, 1fr);
    gap: 80px 80px;
    justify-self: center;
    justify-content: center;
`;

const Pic = styled.img`
    width: 100%;
    aspect-ratio: 7/10;
    object-fit: cover;
`;

const ProjectContainer = styled.div`
    width: 100%;
    height: max-content;
    position: sticky;
    top: 30%;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    @media (max-width: 900px) {
        position: absolute;
        padding: 0 7.5%;
        inset: 0;
        height: 100%;
        width: 85%;
        justify-content: center;
        background-color: rgb(0 0 0 / 50%);
        align-items: center;
    }
`;

const Project = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    column-gap: 4vw;
    @media (max-width: 900px) {
        display: block;
        position: relative;
    }
`;

const ProjectButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    gap: 15px;
`

const ProjectButton = styled.a`
    text-decoration: none;
    padding: 10px 15px;
    background-color: white;
    color: #000;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    letter-spacing: 3px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    transition: all .3s ease-in-out;
    &:hover {
        scale: 1.1;
    }
    & > .projectBtnIcon {
        color: #000;
        font-size: 1.5rem;
        transition: all 0.2s;
    }
`

const ProjectInfo = ({ title, description, role }) => {
    return (
        <>
            <Title>
                {title}
            </Title>
            <Role>
                {role}
            </Role>
            <Note>
                {description}
            </Note>
        </>       
    )
}

const Projects = () => {
  return (
    <ThirdContainer id="project">
        <H1WithLineAbove
            width="max-content" 
            align="center" 
            font="'Playfair Display', serif" 
            bcolor="unset" 
            color="white" 
            lColor="white"  
            data-sal="slide-down"
            data-sal-delay="300"
            data-sal-easing="ease-out-quad"
        >
            Project
        </H1WithLineAbove>
        <ProjectSection>
            <Project>
                <Pic src={ComSysProj}></Pic>
                <ProjectContainer>
                    <ProjectInfo 
                        title={"IOT Trashbin – Intro to Computer System"}
                        role={"EMBEDDED SOFTWARE ENGINEER – LEAD FRONTEND DEVELOPER"}
                        description={"Our project involves creating a smart garbage bin that can sort waste into two compartments using voice commands. The system is based on the Internet of Things (IoT) concept, and data on the sorted waste is displayed on a website. The process involves sending commands from a phone app called AMR Voice to an Arduino device via Bluetooth, which then opens the appropriate compartment based on the type of waste. Our robot design is inspired by Wall-E and Eve, who are known for their environmentally friendly actions."}
                    />
                    <ProjectButtonContainer>
                        <ProjectButton target="_blank" href='https://github.com/nguyennguyenkhuong1212/trashbinstat'>
                            <FontAwesomeIcon icon={faGithub} className='projectBtnIcon'/>
                                GITHUB REPO
                        </ProjectButton>
                    </ProjectButtonContainer>
                </ProjectContainer>
            </Project>
            <Project>
                <ProjectContainer>
                    <ProjectInfo 
                        title={"Pumidoro Music Player – Building IT System"}
                        role={"LEAD FRONTEND DEVELOPER"}
                        description={"Pumidoro Music Player is a website that offers a comprehensive approach to work-life balance. It features a Pomodoro timer with two modes, focus and break, to aid users in achieving their goals. The focus mode is designed to help users concentrate on their work or studies for a specific period, while the break mode enables them to relax. Additionally, the website includes a music player powered by YouTube API, allowing users to enjoy their favorite songs or videos during their breaks. Pumidoro Music Player's primary objective is to enhance productivity while reducing stress and exhaustion by providing a seamless, all-in-one solution for work and entertainment."}
                    />
                    <ProjectButtonContainer>
                        <ProjectButton target="_blank" href='https://github.com/anhminhbo/BITS-Pomodoro-Music'>
                            <FontAwesomeIcon icon={faGithub} className='projectBtnIcon'/>
                                GITHUB REPO
                        </ProjectButton>
                        <ProjectButton target="_blank" href='https://pumidoro-music.bug-fix-squad.com/'>
                        <FontAwesomeIcon icon={faGlobe} className='projectBtnIcon'/>
                                WEBSITE
                        </ProjectButton>
                    </ProjectButtonContainer>
                </ProjectContainer>
                <Pic src={BITSProj}></Pic>
            </Project>
            <Project>
                <Pic src={FWPProj}></Pic>
                <ProjectContainer>
                    <ProjectInfo 
                        title={"Vehicle Rentals – Further Web Programming"}
                        role={"SUPPORT FULLSTACK DEVELOPER"}
                        description={"Introducing our innovative website, which allows you to rent vehicles from anywhere! Our platform connects vehicle owners and renters, making the rental process seamless and adaptable. With our chat and video call system, users can easily discuss rental details, ask questions, and clarify concerns with vehicle owners. Our website makes it simple for renters to connect with owners and arrange vehicle rentals, regardless of the type of vehicle. With our user-friendly website, which includes chat and video call features for efficient communication with vehicle owners, you can say goodbye to cumbersome rental procedures and enjoy the convenience of renting vehicles."}
                    />
                    <ProjectButtonContainer>
                        <ProjectButton target="_blank" href='https://www.youtube.com/watch?v=9Lwe1jC6K4Q'>
                            <FontAwesomeIcon icon={faPlay} className='projectBtnIcon'/>
                                VIDEO
                        </ProjectButton>
                    </ProjectButtonContainer>
                </ProjectContainer>
            </Project>
            <Project>
                <ProjectContainer>
                    <ProjectInfo 
                        title={"Waste Sense – Capstone Project"}
                        role={"DEVOPS-FULLSTACK DEVELOPER – ALGORITHM DESIGNER"}
                        description={`The "Waste Sense" project is an IoT-driven waste management system designed to optimize waste collection and reduce environmental impact in urban areas. By using sensors embedded in waste bins to monitor fill levels in real-time, the system triggers notifications when bins reach a certain capacity. It then employs advanced algorithms to dynamically create the most efficient collection routes, prioritizing high-accumulation areas. This approach enhances collection efficiency, minimizes fuel consumption, and lowers emissions, contributing to sustainable waste management practices.`}
                    />
                    <ProjectButtonContainer>
                        <ProjectButton target="_blank" href='https://github.com/nguyennguyenkhuong1212/capstoneproject-wastemanagementIoT'>
                            <FontAwesomeIcon icon={faGithub} className='projectBtnIcon'/>
                                GITHUB REPO
                        </ProjectButton>
                        {/* <ProjectButton>
                            <FontAwesomeIcon icon={faPlay} className='projectBtnIcon'/>
                                VIDEO
                        </ProjectButton> */}
                        <ProjectButton target="_blank" href='https://wastemanagementtechtitans.netlify.app/'>
                            <FontAwesomeIcon icon={faGlobe} className='projectBtnIcon'/>
                                WEBSITE
                        </ProjectButton>
                    </ProjectButtonContainer>
                </ProjectContainer>
                <Pic src={CapProj}></Pic>
            </Project>
        </ProjectSection>
    </ThirdContainer>
  )
}

export default Projects