import React from 'react'
import styled from "styled-components";
import audio from '../../audio/music.mp3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

const MusicPlayerContainer = styled.div`
    position: absolute;
    width: 250px;
    height: 250px;
    transform: translate(-120px, -170px);
    @media (max-width: 1450px) {
        position: relative;
        transform: unset;
    }

    @media (max-width: 750px) {
        transform: translateX(20vw);
    }
`;

const MusicPlayerDecorativeText = styled.text`
    position: absolute;
    font-size: 12.5px;
    fill: white;
    transform-origin: 50% 50%;
    animation: rotate 100s linear infinite;
    font-family: "Source Code Pro", monospace;
    visibility: ${props => (props.isPlaying ? "visible" : "hidden")};
    opacity: ${props => (props.isPlaying ? "1" : "0")};
    transition: .3s all ease-in-out;
    @keyframes rotate{
    	from {
        	transform: rotateZ(0deg);
        }
        to {
        	transform: rotateZ(360deg);
        }
    }
`

const MusicPlayerImg = styled.img`
    position: absolute;
    inset: 0;
    margin: auto;
    border-radius: 50%;
    width: 75%;
    height: 75%;
    object-fit: cover;
    filter: grayscale(60%);
`

const MusicPlayerSongInfo = styled.div`
    position: absolute;
    inset: 0;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 50%);
    margin: auto;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    box-sizing: border-box;
    opacity: 0;
    transition: .3s all ease-in-out;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 1450px) {
        background-color: rgb(0 0 0 / 50%);
        opacity: 1;
    }
`

const MusicPlayerSongName = styled.div`
    font-size: 0.6rem;
    letter-spacing: 0.4px;
    font-family: "Montserrat", sans-serif;
`

const MusicPlayerSongAuthor = styled.div`
    font-family: "Martel Sans", sans-serif;
    letter-spacing: 0.4px;
`

const MusicPlayerTogglePlayingButton = styled.button`
    padding: 0;
    background: none;
    border: 0;
    color: white;
    cursor: pointer;
    & > .play-btn {
        font-size: 1.5rem;
    }
    & > .pause-btn {
        font-size: 1.6rem;
    }
`

const MusicPlayer = ({ audioPlayer, togglePlaying, isPlaying }) => {
    return (
        <>
            <MusicPlayerContainer class="container">
                <svg width="250" height="250">
                    <path id="curve" d="M25 125 A 100 100 0 1 1 25 127"></path>
                    <MusicPlayerDecorativeText className="text" isPlaying={isPlaying}>
                        <textPath href="#curve">
                            Now playing • Now playing • Now playing • Now playing • Now playing • Now playing •  
                        </textPath>
                    </MusicPlayerDecorativeText>
                </svg>
                <MusicPlayerImg src="https://i.pinimg.com/736x/ca/1a/00/ca1a00f5ac24572be22c8a145f45e0b9.jpg"></MusicPlayerImg>
                <MusicPlayerSongInfo>
                    <MusicPlayerSongName>Merry Christmas Mr. Lawrence</MusicPlayerSongName>
                    <MusicPlayerSongAuthor>by Ryuichi Sakamoto</MusicPlayerSongAuthor>
                    <MusicPlayerTogglePlayingButton onClick={togglePlaying} id="toggleBtn">
                    <FontAwesomeIcon icon={(isPlaying ? faPause : faPlay)} className={(isPlaying ? 'pause-btn' : 'play-btn')}/>
                    </MusicPlayerTogglePlayingButton>
                </MusicPlayerSongInfo>
                <audio
                    ref={audioPlayer}
                    src={audio}
                    preload="metadata"
                    autoPlay
                />
            </MusicPlayerContainer>
        </>
        
    )
}

export default MusicPlayer